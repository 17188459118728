/* You can add global styles to this file, and also import other style files */
::ng-deep .login-main-container {
  background: #000 !important;
}

::-webkit-scrollbar {
  width: 0.1px;
  background-color: transparent;
}

.custom-shadow {
  box-shadow: -2px -2px 11px -5px rgba(60, 45, 121, 1) !important;
}

.table-div {
  /* display: flex !important; */
  width: 100% !important;
}

.dashboard-table-container {
  padding: 10px 20px !important;
  border-radius: 5px !important;
  display: flex !important;
  width: 100% !important;
  background-color: white !important;
}
@media screen and (max-width: 768px) {
  .dashboard-table-container {
    overflow-x: scroll;
  }
}
/* ::-webkit-scrollbar-track {
  background: transparent;
  width: 0.1em;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
} */
